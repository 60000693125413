<template>
<div>

  <el-descriptions direction="vertical" :column="4" border>
    <el-descriptions-item label="标题">第一个信息</el-descriptions-item>
    <el-descriptions-item label="发信人">王小虎</el-descriptions-item>
    <el-descriptions-item label="信息类型">个人消息</el-descriptions-item>
    <el-descriptions-item label="信息日期">2022-06-08</el-descriptions-item>
    <el-descriptions-item label="内容">
      (1)查看和下载手机端测到的历史心电数据(包含心电图)，以及专家系统的智能诊断结果。

      (2)在得到的心律失常疾病快速诊断结果后，给出疑似度的趋势分析，让您对自己的健康状况心中有数。

      (3)上传和下载在医院得到的体检报告和个人健康档案，只要有网络就能随时查询，不必担心纸质档案的意外丢失。

      (4)建立属于您自己的电子医疗档案，您可以更完整地保存个人的健康信息如病史、环境和生活习惯，有了网络数字化健康档案，异地专家可以通过互联网了解到你的详细健康信息，能给出具有更强针对性的个性化健康指导意见，以保护你的健康。而且进行健康管理是以详细的健康资料为基础，健康资料越详尽，健康管理越有效。

      (5)亲友管理。在这里您可以邀请亲友加入心狗健康，组建您自己的专属家庭健康管理组，查看亲友的健康数据，掌握他们的健康状况。
    </el-descriptions-item>
  </el-descriptions>

  <el-button  size="mini" @click="$router.push({ name: 'writeMessage1' })" type="primary" style="margin-top: 25px;font-size: 15px">
    回信
  </el-button>

</div>
</template>

<script>
export default {
  name: "privateMessage1"
}
</script>

<style scoped>

</style>
